:root {
    --primary: #01395e;
    --primary-hover: #00263e;
    --secondary: #e32c3a;
    --primary-text: #01395e;
    --inverted-text: white
  }

.xy-center{
    display: flex;
    justify-content: center;
    align-items: center;
}

.theme-button{
    background-color: var(--primary) !important;
}

.theme-button:hover{
    background-color: var(--primary-hover) !important;
}

.list-group-item{

}
.list-group-item.active, .list-group-item:hover{
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
    color: white;
}

.form-check-input:checked {
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
}